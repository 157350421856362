<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="800px"
        width="800px" version="1.1" id="Capa_1" viewBox="0 0 297 297" xml:space="preserve">
        <g>
            <path
                d="M93.461,142.693c-15.565,0-28.229,12.664-28.229,28.229c0,15.566,12.664,28.23,28.229,28.23   c15.565,0,28.229-12.664,28.229-28.23C121.69,155.357,109.026,142.693,93.461,142.693z M93.461,179.386   c-4.667,0-8.463-3.796-8.463-8.463c0-4.666,3.796-8.462,8.463-8.462s8.463,3.796,8.463,8.462   C101.924,175.59,98.128,179.386,93.461,179.386z" />
            <path
                d="M117.923,97.847c-11.07,0-20.076,9.007-20.076,20.076c0,11.069,9.006,20.076,20.076,20.076   c11.069,0,20.076-9.007,20.076-20.076C137.999,106.854,128.992,97.847,117.923,97.847z M117.923,118.231   c-0.17,0-0.309-0.139-0.309-0.309c0-0.17,0.139-0.309,0.309-0.309c0.17,0,0.309,0.139,0.309,0.309   C118.231,118.093,118.093,118.231,117.923,118.231z" />
            <path
                d="M270.809,32.615H156.502V19.767h10.187c5.459,0,9.884-4.425,9.884-9.883S172.148,0,166.689,0H46.263   c-5.458,0-9.884,4.426-9.884,9.884s4.426,9.883,9.884,9.883H56.45v12.849H26.191c-5.458,0-9.884,4.426-9.884,9.884   s4.426,9.884,9.884,9.884H56.45v43.07c-24.899,16.648-40.143,44.876-40.143,75c0,49.719,40.449,90.168,90.168,90.168   c49.719,0,90.169-40.449,90.169-90.168c0-30.124-15.243-58.351-40.143-75v-43.07h104.423v224.85H42.499   c-5.458,0-9.884,4.426-9.884,9.884c0,5.458,4.426,9.884,9.884,9.884h228.31c5.458,0,9.884-4.426,9.884-9.884V42.499   C280.692,37.041,276.267,32.615,270.809,32.615z M76.217,19.767h60.519v12.849H76.217V19.767z M141.668,109.49   c21.718,12.568,35.21,35.928,35.21,60.963c0,38.819-31.582,70.4-70.402,70.4c-38.818,0-70.4-31.581-70.4-70.4   c0-25.035,13.491-48.394,35.209-60.963c3.053-1.767,4.933-5.027,4.933-8.555V52.383h60.519v48.553   C136.735,104.463,138.615,107.723,141.668,109.49z" />
        </g>
    </svg>
</template>