<template>
  <div class="loader"></div>
</template>

<style scoped>
.loader {
  width: 30px;
  height: 30px;
  border: 6px solid #eaf0f6;
  border-top: 6px solid #007bff;
  border-radius: 50%;
  animation: spinner 0.8s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
