import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import moment from 'moment';
import env from '../../env.js';

export const main = defineStore('main', () => {
  const siteBaseUrl = env.siteBaseUrl;
  const fileBaseUrl = env.fileBaseUrl;
  const qrUrl = env.qrUrl;

  const isProd = !env.baseUrl.startsWith('https://api-test');
  const isLocal = env.baseUrl.startsWith('http://localhost');

  let user = ref(useLocalStorage('user', {}));
  let token = ref(useLocalStorage('token', ''));
  let toastMsg = ref({ msg: '', type: '' });
  let sideMenuCollapsePattern = ref(useLocalStorage('collapsePattern', {}));
  const frequentlyVisitedRoutes = ref(useLocalStorage('frequentlyVisited', []));
  const cart = ref(useLocalStorage('cart', []));
  let openedModal = ref(useLocalStorage('modal', ''));
  let product = ref(useLocalStorage('prod', {}));
  let isSideMenuOpen = ref(false);
  let isLoginModal = ref(false);
  let isLoadingName = ref(false);
  let selectedObject = ref({});
  let selectedHospital = ref({});
  let selectedUser = ref(useLocalStorage('selectedUsr', {}));
  let isClinicOrVitalleo = ref(useLocalStorage('clinicOrVitalleo', false));
  let searchKey = ref(useLocalStorage('key', ''));
  let currLab = ref(useLocalStorage('currLab', {}));
  let currImage = ref(useLocalStorage('currImage', '{}'));
  let prod = ref(useLocalStorage('currImage', {}));
  let cartMap = ref(useLocalStorage('cartMap', {}));
  let nRowsPerPage = ref(useLocalStorage('nRows', 100));
  let pageNo = ref(useLocalStorage('page', 1));
  let timeOutId = ref(undefined);
  let userList = undefined;
  let stripeKey = 'pk_test_6KGf6WilZ4g1uaOTFjkeGlQb00wDSznfSy';
  let appVersion = ref(useLocalStorage('appVersion', null));

  //////LIMS///////
  let order = ref(useLocalStorage('order', {}));
  let billingAddress = ref({});
  let shippingAddress = ref({});

  let permissions = {
    clinic: 27,
    clinic_orders: 29,
    doctor_npi_field: 31,
    qr_scanner: 30,
    vitalleo: 25,
    vitalleo_orders: 32,
    vitalleo_user: 28,
    patient_email: 33,
    return_tracking_field: 34,
    vitalleo_total: 36,
    clinic_total: 35,
    the_lucy_rose_clinic: 37,
    the_lucy_rose_clinic_orders: 38,
    my_health_span: 39,
    my_health_span_orders: 40,
  };
  let regex = {
    password: /^.{6,}$/,
    firstname: /^[a-zA-Z -]{2,}$/,
    lastname: /^[a-zA-Z -]{1,}$/,
    fullname: /^[a-zA-Z -]{3,}$/,
    // eslint-disable-next-line
    email: /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
    mobile: /^[0-9/+]{10}$/, // exact 10 characters only
    generalText: /^.{3,}$/,
    housename: /^[a-zA-Z0-9 -]{3,}$/,
    street: /^[a-zA-Z0-9 -]{3,}$/,
    message: /^.{3,}$/,
    shortText: /^.{1,}$/,
    pin: /^[0-9 ]{6}$/,
    city: /^[a-zA-Z& ]{3,}$/,
    state: /^[a-zA-Z& ]{3,}$/,
    country: /^[a-zA-Z ]{2,}$/,
    number: /^[0-9]{1,}$/,
    floatingNumber: /^[+-]?([0-9]*[.])?[0-9]+$/,
    vitalleoQty: /^([1-9]|[1-4][0-9]|50)$/,
  };

  function setUser(usr) {
    user.value = usr;
  }

  function setToken(val) {
    token.value = val;
  }

  function setOpenedModal(modal) {
    openedModal.value = modal;
  }

  function setCollapsePattern(pattern) {
    sideMenuCollapsePattern.value[pattern.key] = pattern.isCollapsed;
  }

  function setProduct(prod) {
    product.value = prod;
  }

  function setSelectedUsr(usr) {
    selectedUser.value = usr;
  }

  function setClincOrVitalleo(val) {
    isClinicOrVitalleo.value = val;
  }

  function setSearchKey(key) {
    searchKey.value = key;
    // console.log('pinia searchKey', searchKey.value);
  }

  function setCurrLab(currLab) {
    currLab.value = currLab;
  }

  function setCart(cart) {
    cart.value.push(cart);
  }

  function setCurrImage(image) {
    currImage.value = image;
  }

  function setProd(prod) {
    prod.value = prod;
    currImage.value = prod.images[0];
  }

  function setCartMap(prodTitle) {
    cartMap.value = { ...cartMap.value, [prodTitle]: cart.value.length };
  }

  function frequentlyVisitedRoute(payload) {
    const { title } = payload;
    const route = frequentlyVisitedRoutes.value.find((route) => route.title === title);
    if (route) {
      route.visitedCount += 1;
    } else {
      frequentlyVisitedRoutes.value.push(payload);
    }
    // sort by visitedCount
    frequentlyVisitedRoutes.value.sort((rOne, rTwo) => rTwo.visitedCount - rOne.visitedCount);
  }

  function deleteCartItem({ cart, cartMap }, title) {
    const index = cart.value.findIndex((item) => item.title === title);
    console.log('index:', index);
    Vue.delete(cartMap.value, title);
    cart.value.splice(index, 1);
    cart.value.forEach((cartItem, index) => {
      Vue.set(cartMap.value, cartItem.title, index);
    });
    console.log('cartMap:', cartMap);
    console.log('cart:', cart);
  }

  function toast(msg, type) {
    toastMsg.value.msg = msg;
    toastMsg.value.type = type;
    let secs = undefined;
    if (type === 'success') {
      secs = 2000;
    } else {
      secs = 7000;
    }
    console.log('toast secs', secs);
    timeOutId.value = setTimeout(() => {
      toastMsg.value.msg = '';
      toastMsg.value.type = '';
      clearTimeout(timeOutId.value);
    }, secs);
  }

  function setNRows(nRows) {
    nRowsPerPage.value = nRows;
  }

  function setPageNo(num) {
    pageNo.value = num;
  }

  function getDateTime(dateTimeStringTZ) {
    let date = new Date(dateTimeStringTZ).toString();
    return date.substring(4, 15);
  }

  function getDateWithTime(dateTimeStringTZ) {
    if (dateTimeStringTZ) {
      let date = new Date(dateTimeStringTZ).toString();
      return date.substring(4, 21);
    }
    return 'No Date';
  }

  function getCreatedAt(dateTimeString) {
    return moment(dateTimeString).format('MM/DD/YYYY');
  }

  //LIMS
  function addToOrder(obj) {
    const key = obj.key;
    const value = obj.value;
    order.value[key] = value;
    console.log('addToOrder', order.value);
  }

  // example: key = 'billingAddress', value = {name: 'abc', address: 'xyz'}
  function getFromOrderObject(key) {
    return order.value[key];
  }

  // Method to set billing address
  function setBillingAddress(address) {
    billingAddress.value = address;
  }

  // Method to set shipping address
  function setShippingAddress(address) {
    shippingAddress.value = address;
  }

  function stripeInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://js.stripe.com/v3/';
    document.head.appendChild(script);
    return script;
  }

  function setAppVersion(version) {
    appVersion.value = version;
    localStorage.setItem('appVersion', version);
  }

  return {
    isSideMenuOpen,
    toastMsg,
    isLoginModal,
    isLoadingName,
    user,
    token,
    sideMenuCollapsePattern,
    frequentlyVisitedRoutes,
    openedModal,
    product,
    selectedObject,
    selectedHospital,
    selectedUser,
    isClinicOrVitalleo,
    permissions,
    searchKey,
    currLab,
    cart,
    currImage,
    prod,
    cartMap,
    nRowsPerPage,
    pageNo,
    userList,
    siteBaseUrl,
    regex,
    qrUrl,
    appVersion,
    fileBaseUrl,
    isProd,
    isLocal,
    order,
    billingAddress,
    shippingAddress,
    stripeKey,


    setUser,
    setToken,
    setCollapsePattern,
    frequentlyVisitedRoute,
    setOpenedModal,
    setProduct,
    setSelectedUsr,
    setClincOrVitalleo,
    setSearchKey,
    setCurrLab,
    setCart,
    toast,
    setCurrImage,
    setProd,
    setCartMap,
    deleteCartItem,
    setNRows,
    setPageNo,
    getDateWithTime,
    getCreatedAt,
    getDateTime,
    addToOrder,
    getFromOrderObject,
    setBillingAddress,
    setShippingAddress,
    stripeInit,
    setAppVersion,
  };
});
